<template>
  <div class="sub-header">
    <div class="d-flex">
      <SubHeaderButton
        v-if="can('view', 'shipments')"
        :to="shipmentsPage"
        icon="truck"
        name="Shipments"
        style="width: 180px"
        :is-add-new="false"
      />
      <SubHeaderButton
        v-if="can('view', 'assignmentsList')"
        :to="assignmentsList"
        name="Assignments"
        icon="file-document-multiple-outline"
        @add-new="navigateToCreateAssignments"
      />
      <SubHeaderButton
        v-if="can('manage', 'customer')"
        :to="customersArea"
        icon="account-outline"
        name="Customers area"
        @add-new="navigateToCreateCustomer"
      />
      <SubHeaderButton
        v-if="can('view', 'statistics')"
        :to="statistics"
        icon="file-document-outline"
        :is-add-new="false"
        name="Statistics"
        style="width: 160px"
      />
      <SubHeaderButton
        v-if="can('view', 'pickups-list')"
        :to="pickupsList"
        icon="magnify"
        name="Pickups"
        style="width: 160px"
        :is-add-new="false"
      />
      <SubHeaderButton
        v-if="can('manage', 'faq')"
        :to="faqPage"
        icon="help"
        name="FAQ"
        style="width: 180px"
        @add-new="navigateToCreateFaq"
      />
      <SubHeaderButton
        v-if="can('view', 'scan')"
        :to="scanPage"
        icon="file-document-multiple-outline"
        name="Scan"
        style="width: 180px"
        :is-add-new="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { RouterLink } from '@/types/Components/Router'
import { defineComponent, computed, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SubHeaderButton from '@/views/Components/PageParts/SubHeaderButton.vue'
import {
  CUSTOMER_ASSIGNMENTS_CREATE,
  CUSTOMER_ASSIGNMENTS_LIST,
  STAFF_ASSIGNMENTS_CREATE,
  STAFF_ASSIGNMENTS_LIST,
  STAFF_BASE_PRICE_PAGE,
  STAFF_CUSTOMERS_CREATE,
  STAFF_CUSTOMERS_LIST,
  STAFF_STATISTICS,
  FAQ_CREATE,
  FAQ_LIST,
  CUSTOMER_STATISTICS,
  STAFF_PICKUPS_LIST,
  STAFF_SHIPMENTS,
  SCAN_PAGE,
  CUSTOMER_SHIPMENTS,
} from '@/types/router'
import { SINGLE } from '@/const/AssignmentTypes'

export default defineComponent({
  name: 'SubHeader',
  components: { SubHeaderButton },
  setup() {
    const store = useStore()
    const router = useRouter()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const ability: ComputedRef = computed(() => store.getters['ability'])

    const assignmentsList: ComputedRef<RouterLink> = computed(() => {
      return { name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST }
    })
    const createAssignmentsPage: ComputedRef<RouterLink> = computed(() => {
      return { name: isStaff.value ? STAFF_ASSIGNMENTS_CREATE : CUSTOMER_ASSIGNMENTS_CREATE }
    })
    const statistics: ComputedRef<RouterLink> = computed(() => {
      return { name: isStaff.value ? STAFF_STATISTICS : CUSTOMER_STATISTICS }
    })
    const shipmentsPage: ComputedRef<RouterLink> = computed(() => {
      return { name: isStaff.value ? STAFF_SHIPMENTS : CUSTOMER_SHIPMENTS }
    })

    const scanPage: ComputedRef<RouterLink> = computed(() => {
      return { name: SCAN_PAGE }
    })

    const customersArea: ComputedRef<RouterLink> = computed(() => {
      return { name: STAFF_CUSTOMERS_LIST }
    })

    const createCustomerPage: ComputedRef<RouterLink> = computed(() => {
      return { name: STAFF_CUSTOMERS_CREATE }
    })

    const pickupsList: ComputedRef<RouterLink> = computed(() => {
      return { name: STAFF_PICKUPS_LIST }
    })

    const basePricePage: ComputedRef<RouterLink> = computed(() => {
      return { name: STAFF_BASE_PRICE_PAGE }
    })

    const createFaqPage: ComputedRef<RouterLink> = computed(() => {
      return { name: FAQ_CREATE }
    })

    const faqPage: ComputedRef<RouterLink> = computed(() => {
      return { name: FAQ_LIST }
    })

    const navigateToCreateAssignments = () => {
      router.push({
        name: createAssignmentsPage.value.name,
        query: { assignmentType: SINGLE },
      })
    }

    const navigateToCreateCustomer = () => {
      router.push({ name: STAFF_CUSTOMERS_CREATE })
    }

    const navigateToCreateFaq = () => {
      router.push({ name: FAQ_CREATE })
    }

    return {
      faqPage,
      scanPage,
      statistics,
      pickupsList,
      basePricePage,
      createFaqPage,
      customersArea,
      shipmentsPage,
      assignmentsList,
      createCustomerPage,
      createAssignmentsPage,
      navigateToCreateAssignments,
      navigateToCreateCustomer,
      navigateToCreateFaq,
      can: (rule: string, section: string) => ability.value.can(rule, section),
    }
  },
})
</script>
