import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'

export default class ShipmentProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'shipment'

  getList(params: URLSearchParams) {
    return this.submit('get', `/${this.endpoint}?${params}`)
  }

  getShipmentsByTrackingNumber(trackingNumber: string) {
    return this.submit('get', `/${this.endpoint}?shipmentNumber[]=${trackingNumber}&page=1&limit=10`)
  }
}
