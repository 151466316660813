<template>
  <tr>
    <td>
      <RowDate :date="message.created_at" />
    </td>
    <td>
      <span class="mr-1">
        <i v-if="isSms" class="fa fa-phone" />
        <i v-else class="fa fa-envelope" />
      </span>
      {{ message.sent_to }}
    </td>
    <td>
      <div>
        {{ messageData }}
        <a v-if="displayViewLink" :href="linkToMailPage" target="_blank"> View </a>
      </div>
      <div v-if="message.error" class="pt-3 text-danger">
        <i class="fa fa-exclamation-triangle fa-fw" />
        {{ message.error }}
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import type { Message } from '@/types/Models/Shipment'
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import moment from 'moment'

export default defineComponent({
  name: 'Message',
  components: { RowDate },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  setup(props) {
    const isSms: ComputedRef<boolean> = computed(() => props.message.type === 'sms')
    const parsedData: ComputedRef = computed(() => (isSms.value ? null : JSON.parse(props.message.data)))
    const emailHandle: ComputedRef<string> = computed(() => parsedData.value?.handle || '')
    const emailName: ComputedRef<string> = computed(() => {
      const names: Record<string, string> = {
        'labelportal.shipment.service_point_delivery': 'Parcel was diverted to a service-point',
        'labelportal.shipment.dispatched': 'Parcel is in transit',
        'labelportal.shipment.package.delivered': 'Parcel was delivered',
        default_sms: 'Default SMS',
        default_slack: 'Default Slack',
        default_email: 'Default Email',
        'labelportal.customer.report_released': 'Report released',
        'labelportal.customer.labels_released': 'Labels released',
        'labelportal.statistics.report_generated': 'Report generated',
        'labelportal.integrations.InitLogtradeIntegration': 'Logtrade integration initiated',
        'labelportal.admin.batch_assignment_created': 'Batch assignment created',
        'labelportal.admin.batch_assignment_deleted': 'Batch assignment deleted',
        'labelportal.shipment.parcel.business.dispatched': 'Parcel is in transit',
        'labelportal.shipment.parcel.consumer.available_for_collecting': 'Parcel can be collected at service-point',
        'labelportal.shipment.parcel.consumer.dispatched': 'Parcel is in transit',
        'labelportal.shipment.delivered': 'Parcel was delivered',
        'labelportal.shipment.package.dispatched': 'Package is in transit',
        'labelportal.shipment.package.service_point_diversion': 'Parcel was diverted to a service-point',
        'labelportal.shipment.package.service_point_diversion_oblivious': 'Parcel was diverted to a service-point',
        'labelportal.shipment.service_point_out_for_delivery': 'Parcel can be collected at service-point',
      }
      return names[emailHandle.value] || emailHandle.value
    })

    const messageData: ComputedRef<string> = computed(() => (isSms.value ? props.message.data : emailName.value))

    const linkToMailPage: ComputedRef<string> = computed(
      () => `${import.meta.env.VITE_APP_MAILER_URL}/messages/${props.message.item_id}/${emailHandle.value}/content`,
    )

    const displayViewLink: ComputedRef<boolean> = computed(
      () =>
        !isSms.value &&
        !!emailHandle.value &&
        moment(props.message.created_at).isAfter('2024-12-18 14:00:00') &&
        moment(props.message.created_at).isAfter(moment().subtract(3, 'months')),
    )

    return {
      isSms,
      parsedData,
      messageData,
      emailHandle,
      linkToMailPage,
      displayViewLink,
    }
  },
})
</script>
