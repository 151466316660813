<template>
  <div class="center-text">
    <input ref="inputRef" v-model="inputValue" type="text" placeholder="Search" class="track-num" />
    <button class="btn btn-green" @click="clearNumber">Clear</button>
    <button class="btn btn-green" @click="searchShipment">Submit</button>
  </div>
  <div v-if="shipments.length === 0 && search" class="not-found center-text">
    Are you sure you scanned the correct barcode?
  </div>
  <div v-if="received" class="received center-text">Shipment received in facility</div>
  <div v-if="error" class="receive-error center-text">{{ error }}</div>
  <ShipmentsScan v-if="shipments.length > 0" :shipments="shipments" />
</template>

<script lang="ts">
import type { Shipment } from '@/types/Models/Shipment'
import type { AxiosCollection } from '@/types/Components/Response'
import { defineComponent, onMounted, onUnmounted, type Ref, ref, watch } from 'vue'
import ItemsProxy from '@/proxies/ItemsProxy'
import ShipmentProxy from '@/proxies/ShipmentProxy'
import ShipmentsScan from '@/views/Components/Shipments/ShipmentsScan.vue'

export default defineComponent({
  name: 'ScanPage',
  components: { ShipmentsScan },
  setup() {
    const inputRef: Ref<HTMLInputElement | null> = ref(null)
    const inputValue: Ref<string> = ref('')
    const shipments: Ref<Shipment[]> = ref([])
    const received: Ref<boolean> = ref(false)
    const search: Ref<boolean> = ref(false)
    const error: Ref<string> = ref('')
    const shipmentProxy = new ShipmentProxy()
    const itemsProxy = new ItemsProxy()
    let timeout: any

    const focusInput = () => {
      if (inputRef.value) {
        inputRef.value.focus()
        const length = inputRef.value.value.length
        inputRef.value.setSelectionRange(length, length)
      }
    }
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        focusInput()
        receiveFirst()
      }
    }
    const receiveFirst = () => {
      search.value = false
      error.value = ''
      const firstShipment = shipments.value[0]
      if (firstShipment) {
        itemsProxy
          .receive(firstShipment.id)
          .then(() => {
            shipments.value = shipments.value.filter((shipment) => shipment.id !== firstShipment.id)
            received.value = true
            setTimeout(() => {
              received.value = false
            }, 3000)
          })
          .catch(() => {
            error.value = 'Error receiving shipment'
          })
      }
    }
    const searchShipment = () => {
      clearTimeout(timeout)
      focusInput()
      if (inputValue.value.length > 8) {
        search.value = false
        timeout = setTimeout(() => {
          shipmentProxy
            .getShipmentsByTrackingNumber(inputValue.value)
            .then((response: AxiosCollection<Shipment>) => {
              shipments.value = response.data
              clearNumber()
              search.value = true
            })
            .catch(() => {
              search.value = true
            })
        }, 500)
      }
    }
    const clearNumber = () => {
      inputValue.value = ''
      focusInput()
    }

    watch(inputValue, searchShipment)

    onMounted(() => {
      const interval = setInterval(focusInput, 3000)
      focusInput()
      onUnmounted(() => {
        clearInterval(interval)
      })
      window.addEventListener('keydown', handleKeyPress)
    })

    return {
      searchShipment,
      clearNumber,
      inputValue,
      shipments,
      received,
      inputRef,
      search,
      error,
    }
  },
})
</script>

<style lang="scss" scoped>
.track-num,
.not-found,
.received,
.receive-error {
  margin-bottom: 20px;
  font-size: 24px;
}
.track-num {
  width: 50%;
  height: 44px;
  padding: 0 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btn {
  margin-left: 10px;
  margin-top: -3px;
}
.not-found,
.receive-error {
  color: red;
}
.received {
  color: green;
}
</style>
