<template>
  <div class="pb-3">
    <div class="row">
      <div class="col-md-3">
        <div class="child-row-item__title">Proxy</div>
        <div class="child-row-item__subtitle">
          <div v-if="shipment.shouldUseProxy" class="text-success">
            <i class="fa fa-check" />
            Enabled
          </div>
          <div v-else class="text-muted">
            <i class="fa fa-info" />
            Disabled
          </div>
        </div>
      </div>
      <div v-if="shipment.forwardedToServicePoint" class="col-md-3">
        <div class="child-row-item__title">Forwarded to service-point</div>
        <div class="child-row-item__subtitle">
          {{ forwardType }}
        </div>
      </div>
      <div v-if="shipment.forwardedToServicePoint && servicePointNotAvailable" class="col-md-3">
        <div class="child-row-item__title">Service-point</div>
        <div class="child-row-item__subtitle">
          <div class="text-danger">
            <i class="fa fa-warning" />
            Not available
          </div>
        </div>
      </div>
      <div v-if="shipment.collectionCode" class="col-md-2">
        <div class="child-row-item__title">Code</div>
        <div class="child-row-item__subtitle">
          {{ shipment.collectionCode }}
        </div>
      </div>
      <div v-if="collectionDeadline" class="col-md-2">
        <div class="child-row-item__title">Collect until</div>
        <div class="child-row-item__subtitle">
          {{ collectionDeadline }}
        </div>
      </div>
    </div>
    <div v-if="shipment.externalTracking" class="row pt-3">
      <div class="col-md-3">
        <div class="child-row-item__title">Partner</div>
        <div class="child-row-item__subtitle">
          {{ shipment.externalTracking.partnerName }}
        </div>
      </div>
      <div class="col-md-9">
        <div class="child-row-item__title">External tracking link</div>
        <div class="child-row-item__subtitle">
          <a :href="shipment.externalTracking.link" target="_blank">{{ shipment.externalTracking.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'
import { readableDate } from '@/services/Helpers'

export default defineComponent({
  name: 'ProxyDetails',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const forwardType: ComputedRef<string | null> = computed(() => {
      switch (props.shipment.forwardType) {
        case 'carrier_and_shipment_number_change':
          return 'New carrier, new tracking'
        case 'carrier_change':
          return 'New carrier, same tracking'
        case 'simple_forward':
          return 'Same carrier, same tracking'
        default:
          return null
      }
    })

    const servicePointNotAvailable: ComputedRef<boolean> = computed(() => !props.shipment.servicePoints?.length)

    const collectionDeadline: ComputedRef<string | null> = computed(() =>
      props.shipment.collectionDeadline ? readableDate(props.shipment.collectionDeadline) : null,
    )

    return {
      forwardType,
      servicePointNotAvailable,
      collectionDeadline,
    }
  },
})
</script>
