<template>
  <div class="assignment-action d-flex mr-4 user-info">
    <div class="d-flex flex-column mr-3">
      <div v-if="user.shortName" class="color-grey">Short name</div>
      <div class="color-black">
        {{ user.shortName }}
      </div>
    </div>
    <div v-if="user.clientNumber" class="d-flex flex-column mr-3">
      <div class="color-grey">Client №</div>
      <div class="color-black">
        {{ senderAddress?.clientNumber || user.clientNumber }}
      </div>
    </div>
    <div v-if="user.company" :class="{ 'd-flex': true, 'flex-column': true, 'mr-3': !!createdDate }">
      <div class="color-grey">Company</div>
      <div class="color-black">
        {{ senderAddress?.company || user.company }}
      </div>
    </div>
    <div v-if="createdDate" class="d-flex flex-column">
      <div class="color-grey">Created date</div>
      <div class="color-black">
        {{ createdDate }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { readableDateTime } from '@/services/Helpers'
import { defineComponent, computed, type ComputedRef } from 'vue'
import type { Assignment } from '@/types/Models/Assignment'
import type { Address } from '@/types/Models/Customer'
import type { User } from '@/types/Models/User'

export default defineComponent({
  name: 'UserInfo',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<Assignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const user: ComputedRef<User> = computed(() => assignment.value.user)
    const senderAddress: ComputedRef<Address | null | undefined> = computed(() => assignment.value.senderAddress)
    const createdDate: ComputedRef<string | null> = computed(() => readableDateTime(assignment.value.createdAt))

    return {
      user,
      createdDate,
      senderAddress,
    }
  },
})
</script>
