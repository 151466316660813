import { BaseProxy, type ProxyMethods } from '@/proxies/Proxy'

export default class ItemsProxy extends BaseProxy implements ProxyMethods {
  public endpoint = 'items'

  validate(itemId: string) {
    return this.submit('post', `${this.endpoint}/${itemId}/validate`)
  }

  receive(itemId: string) {
    return this.submit('put', `${this.endpoint}/${itemId}/receive`)
  }
}
