<template>
  <div class="w-100 m-auto shipments-list">
    <EasyDataTable
      ref="dataTable"
      table-class-name="vue3-easy-data-table table-shadow"
      :headers="headers"
      :items="shipments"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data> - </template>
      <template #item="{ column, item }">
        <span v-if="column === 'user'">
          {{ item.user.company }}
        </span>
        <span v-if="column === 'recipient'">
          <RecipientCell :shipment="item" />
        </span>
        <span v-if="column === 'shipmentNumber'">
          <ShipmentNumberCell :shipment="item" />
        </span>
        <span v-if="column === 'lastTrackingEventStatus'">
          <TrackingStatusCell :shipment="item" />
        </span>
        <span v-if="column === 'carrierHandle'">
          <ShipmentsCarrierCell :shipment="item" />
        </span>
        <span v-if="column === 'action'">
          <button v-if="getIndex(item) === 1" class="btn btn-green">Receive (click "Enter")</button>
          <button v-else class="btn btn-green">Receive</button>
        </span>
      </template>
    </EasyDataTable>
  </div>
</template>

<script lang="ts">
import type { Shipment } from '@/types/Models/Shipment'
import { ref, defineComponent } from 'vue'
import RecipientCell from '@/views/Components/Shipments/Cells/Recipient.vue'
import ShipmentsCarrierCell from '@/views/Components/Shipments/Cells/ShipmentsCarrierCell.vue'
import TrackingStatusCell from '@/views/Components/Shipments/Cells/TrackingStatus.vue'
import ShipmentNumberCell from '@/views/Components/Shipments/Cells/ShipmentNumber.vue'
import EasyDataTable from 'vue3-easy-data-table'

export default defineComponent({
  name: 'ShipmentsScan',
  components: {
    TrackingStatusCell,
    ShipmentNumberCell,
    ShipmentsCarrierCell,
    RecipientCell,
    EasyDataTable,
  },
  props: {
    shipments: {
      type: Array as () => Shipment[],
      required: true,
    },
  },
  setup(props) {
    const dataTable = ref()
    const headers = [
      { text: 'Shipment number', value: 'shipmentNumber' },
      { text: 'Recipient', value: 'recipient' },
      { text: 'Customer', value: 'user' },
      { text: 'Carrier', value: 'carrierHandle' },
      { text: 'Status', value: 'lastTrackingEventStatus' },
      { text: '', value: 'action' },
    ]
    const getIndex = (item: Shipment) => props.shipments.findIndex((shipment: Shipment) => shipment.id === item.id) + 1

    return {
      dataTable,
      headers,
      getIndex,
    }
  },
})
</script>

<style lang="scss">
.shipments-list {
  .vue3-easy-data-table__main {
    min-height: auto !important;
  }
}
</style>
