<template>
  <div>
    <div>
      <div v-if="shipment.shouldUseProxy">
        <small class="text-success">
          <i class="fa fa-check" />
          Enabled
        </small>
      </div>
      <div v-else>
        <small class="text-muted">
          <i class="fa fa-info" />
          Disabled
        </small>
      </div>
    </div>
    <div v-if="shipment.forwardedToServicePoint">
      <div>
        <small class="text-secondary">Forwarded to SP</small>
      </div>
      <div v-if="servicePointNotAvailable">
        <small class="text-danger">
          <i class="fa fa-warning" />
          SP not available
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent, type PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ProxyCell',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const servicePointNotAvailable: ComputedRef<boolean> = computed(() => !props.shipment.servicePoints?.length)

    return {
      servicePointNotAvailable,
    }
  },
})
</script>
