import ShipmentProxy from '@/proxies/ShipmentProxy'
import { QueryParams } from '@/const/ShipmentsListQueryFilters'
import type { Ref } from 'vue'
import type { LocationQueryValue, RouteLocationNormalizedLoaded } from 'vue-router'
import type { KeyAccessor } from '@/types/Helpers/Arrays'

const transformStatuses = (input: string | LocationQueryValue | LocationQueryValue[]) => {
  if (!input || !input.length) {
    return null
  }

  const map: KeyAccessor = {
    out_for_delivery: ['in_transit', 'out_for_delivery'],
    delivered: ['delivered'],
    delivery_issue: ['exception', 'failed_attempt'],
    returned: ['returned'],
  }

  const array = typeof input === 'string' ? [input] : input

  return array.map((status: any) => map[status] || [status]).flatMap((status: string) => status)
}

const appendToParams = (params: any, key: string) => (value: LocationQueryValue) =>
  params.append(`${key}[]`, value?.toString().trim())

export const makeRequestFunction = (route: RouteLocationNormalizedLoaded) => (serverOptions: Ref) => {
  const params = new URLSearchParams({
    limit: String(serverOptions.value.rowsPerPage || 50),
    page: String(serverOptions.value.page || 1),
  })

  const lastTrackingStatus = transformStatuses(route?.query.statuses)
  if (lastTrackingStatus) {
    const appender = appendToParams(params, QueryParams.LAST_TRACKING_STATUS)
    lastTrackingStatus.forEach(appender)
  }

  if (route?.query.shipmentNumber) {
    const appender = appendToParams(params, QueryParams.SHIPMENT_NUMBER)
    String(route.query.shipmentNumber)?.split(',').forEach(appender)
  }

  if (route?.query.search) {
    params.append(QueryParams.SEARCH, String(route.query.search))
  }

  if (route?.query.country) {
    const appender = appendToParams(params, QueryParams.COUNTRY)
    String(route.query.country)?.split(',').forEach(appender)
  }

  if (route?.query.customer) {
    const appender = appendToParams(params, QueryParams.CUSTOMER)
    String(route.query.customer)?.split(',').forEach(appender)
  }

  if (route?.query.assignment) {
    const appender = appendToParams(params, QueryParams.ASSIGNMENT)
    String(route.query.assignment)?.split(',').forEach(appender)
  }

  if (route?.query.carriers) {
    const appender = appendToParams(params, QueryParams.CARRIER_HANDLE)
    if (!Array.isArray(route.query.carriers)) {
      route.query.carriers = route.query.carriers?.split(',')
    }
    route.query.carriers.forEach(appender)
  }

  if (route?.query.dateFrom) {
    params.append(QueryParams.DATE_FROM, String(route.query.dateFrom))
  }

  if (route?.query.dateTo) {
    params.append(QueryParams.DATE_TO, String(route.query.dateTo))
  }

  if (route?.query.packagesDivertedToServicePoint) {
    params.append(QueryParams.PACKAGES_DIVERTED_TO_SERVICE_POINT, 'true')
  }

  if (route?.query.withServicePointCollectingProblem) {
    params.append(QueryParams.WITH_SERVICE_POINT_COLLECTING_PROBLEM, 'true')
  }

  if (route?.query.withRecipientNotificationProblem) {
    params.append(QueryParams.WITH_RECIPIENT_NOTIFICATION_PROBLEM, 'true')
  }

  return new ShipmentProxy().getList(params)
}
